<template>
	<a-drawer :zIndex="1000" class="role-overlay" :title="title" @close="close" :visible="roleModal.show"
		:drawerStyle="{ overflow: 'auto' }">

		<a-form style="margin-bottom: 100px;">
			<a-form-item>
				<div slot="label">Role Name<span style="color: var(--danger);">*</span></div>
				<a-input v-if="role.name === 'bh_agent_manager'" value="VIP Broker Manager"
					placeholder="Example: Designer" required style="max-width:400px" disabled />
				<a-input v-else v-model="role.name" placeholder="Example: Designer" required style="max-width:400px" />
			</a-form-item>
			<div v-for="cat in sortedPermissions" :key="cat.name">
				<div style="font-weight: bold; margin-top: 30px;">{{ cat.name }}</div>
				<hr style="margin-left: -24px; margin-right: -24px;" />
				<a-form-item v-for="app in cat.apps" :key="app.id">
					<div class="permission-grid">
						<div style="display: inline-block; font-size:13px; line-height:1.4;">{{ app.name }}</div>
						<div>
							<a-radio-group size="small" v-model="role.permissions[app.id]" class="permissionButtons">
								<div v-for="level in app.levels" :key="`${app.id}-${level.value}`">
									<a-tooltip :mouseEnterDelay="0.5" :title="level.tooltip" v-if="level.tooltip"
										overlayClassName="change-tooltip-color">
										<a-radio-button style="width:auto" :value="level.value">
											{{ level.label }}
										</a-radio-button>
									</a-tooltip>
									<a-radio-button v-else style="width:auto"
										:disabled="level.disabled ? level.disabled : false" :value="level.value">
										{{ level.label }}
									</a-radio-button>
								</div>
							</a-radio-group>
						</div>
					</div>
				</a-form-item>
			</div>
		</a-form>

		<div
			:style="{ zIndex: '1001', position: 'absolute', left: 0, bottom: 0, width: '100%', borderTop: '1px solid #e9e9e9', padding: '20px 26px', background: '#fff', textAlign: 'right', }">
			<a-button style="width:100px" :style="{ marginRight: '20px' }" class="cancel-button" @click="close">
				CANCEL
			</a-button>
			<a-button style="width:100px" @click="submitRole" class="button" type="primary">
				{{ roleModal.type === 'add' ? 'CREATE' : 'UPDATE' }}
			</a-button>
		</div>
	</a-drawer>
</template>

<script>
	const perms = {
		filemanager: 10,
		amenities: 0,
		models: 0,
		siteplan: 0,
		condogrid: 0,
		forms: 0,
		contracts: 0,
		features: 0,
		sites: 0,
		touchscreen: 0,
		email: 0,
		contacts: 0,
		transactions: 0,
		content: 20,
		team: 0,
		reports: 0,
		inventory: 0,
		domain: 0,
		condounits: 0,
		masterplan: 0,
		worksheets: 0,
		"marketing-automation": 0,
		billing: 0,
	}
	export default {
		props: {
			visible: {
				type: Boolean,
				default: false
			},

		},
		data: () => ({
			role: {
				name: '',
				id: '',
				permissions: JSON.parse(JSON.stringify(perms)),
			},
			title: 'Add Role'
		}),
		watch: {
			roleModal: {
				deep: true,
				handler(val) {
					if (val.show) {
						if (val.type === 'edit') {
							this.role = JSON.parse(JSON.stringify(val.role))
							this.title = 'Edit Role'
						}
					} else {
						this.role = {
							name: '',
							id: '',
							permissions: JSON.parse(JSON.stringify(perms)),
						}
						this.title = 'Add Role'
					}
				}
			},
		},
		computed: {
			instance() {
				return this.$store.state.instance
			},
			isCurrentUserOwner() {
				return [this.instance.owner?.id, this.instance.billingOwner?.id].includes(this.$store.state.user.user?.id)
			},
			instanceApps() {
				return this.instance.apps.map((a) => a.subdomain)
			},
			roleModal() {
				return this.$store.state.roleModal
			},
			sortedPermissions() {
				if (!this.instance) return []
				return this.permissionArray.map(cat => {
					cat.apps = cat.apps.filter(app => {
						if (app.id === 'billing' && this.isCurrentUserOwner) return true
						if (!this.instanceApps.includes(app.id)) return false
						if (app.type) return this.instance.productType === app.type
						return true
					})
					return cat
				}).filter((cat) => cat.apps.length)
			},
			permissionArray() {
				return [
					{
						name: 'Administration & Team Management',
						apps: [
							{
								name: 'Reports & Analytics (RA)',
								id: 'reports',
								levels: [
									{
										label: 'None',
										value: 0
									},
									{
										label: 'Min. Access',
										value: 10,
										tooltip: 'Can only see your own activities (sales reps) including lead reports, pipeline reports, sales reports'
									},
									{
										label: 'Med. Access',
										value: 20,
										tooltip: 'Can see all reports except Inventory and Website reports'
									},
									{
										label: 'Full',
										value: 40,
									}
								],
							},
							{
								name: 'Team Configurator (TC)',
								id: 'team',
								levels: [
									{
										label: 'None',
										value: 0
									},
									{
										label: 'Min. Access',
										value: 10,
										disabled: true
									},
									{
										label: 'Med. Access',
										value: 20,
										tooltip: 'Can invite a new user. Cannot: Add, Modify or Delete role/user role'
									},
									{
										label: 'Full',
										value: 40,
										disabled: true
									}
								],
							},
							{
								name: 'Billing',
								id: 'billing',
								levels: [
									{
										label: 'None',
										value: 0
									},
									{
										label: 'Min. Access',
										value: 10,
										tooltip: 'Can view invoices'
									},
									{
										label: 'Med. Access',
										value: 20,
										disabled: true
									},
									{
										label: 'Full',
										value: 40,
										disabled: true
									}
								],
							},
						]
					},
					{
						name: 'Project Configuration',
						apps: [
							{
								name: 'Content Asset Management (CAM)',
								id: 'filemanager',
								levels: [
									{
										label: 'None',
										value: 0
									},
									{
										label: 'Min. Access',
										value: 10,
										tooltip: 'Can: Add. Cannot: Delete'
									},
									{
										label: 'Med. Access',
										value: 20,
										disabled: true
									},
									{
										label: 'Full',
										value: 40,
									}

								],
							},
							{
								name: 'Amenity Map Configurator (AMC)',
								id: 'amenities',
								levels: [
									{
										label: 'None',
										value: 0
									},
									{
										label: 'Min. Access',
										value: 10,
										disabled: true
									},
									{
										label: 'Med. Access',
										value: 20,
										disabled: true
									},
									{
										label: 'Full',
										value: 40,
									}
								],
							},
							{
								name: 'Form & Survey Configurator (FSC)',
								id: 'forms',
								levels: [
									{
										label: 'None',
										value: 0
									},
									{
										label: 'Min. Access',
										value: 10,
										tooltip: 'Can: Add & Edit. Cannot: Delete',
									},
									{
										label: 'Med. Access',
										value: 20,
										disabled: true
									},
									{
										label: 'Full',
										value: 40,
									}
								],
							},
							{
								name: 'Contract Configurator (CC)',
								id: 'contracts',
								levels: [
									{
										label: 'None',
										value: 0
									},
									{
										label: 'Min. Access',
										value: 10,
										tooltip: 'Can: Add & Edit. Cannot: Delete',
									},
									{
										label: 'Med. Access',
										value: 20,
										disabled: true
									},
									{
										label: 'Full',
										value: 40,
									}
								],
							},
						]
					},
					{
						name: 'Product Management',
						apps: [
							{
								name: 'Home Model Configurator (HMC)',
								id: 'models',
								type: 'lowrise',
								levels: [
									{
										label: 'None',
										value: 0
									},
									{
										label: 'Min. Access',
										value: 10,
										tooltip: 'Can: Add, edit non published. Cannot: Modify Status or Delete',
									},
									{
										label: 'Med. Access',
										value: 20,
										tooltip: 'Can: Add, edit including published. Cannot: Modify Status or Delete',
									},
									{
										label: 'Full',
										value: 40,
									},
								]
							},
							{
								name: 'Features Configurator (FC)',
								id: 'features',
								levels: [
									{
										label: 'None',
										value: 0
									},
									{
										label: 'Min. Access',
										value: 10,
										tooltip: 'Can: Create & Edit. Cannot: Delete',
									},
									{
										label: 'Med. Access',
										value: 20,
										disabled: true
									},
									{
										label: 'Full',
										value: 40,
									}
								],
							},
							{
								name: 'Site Plan Configurator (SPC)',
								id: 'siteplan',
								type: 'lowrise',
								levels: [
									{
										label: 'None',
										value: 0
									},
									{
										label: 'Min. Access',
										value: 10,
										tooltip: 'Can: Add, edit non published. Cannot: Publish or Delete'
									},
									{
										label: 'Med. Access',
										value: 20,
										tooltip: 'Can: Add, edit including published. Cannot: Delete, Publish new lots, Apply or Edit Architectural rules'
									},
									{
										label: 'Full',
										value: 40,
									}

								],
							},
							{
								name: 'Master Plan Configurator (MPC) ',
								id: 'masterplan',
								type: 'lowrise',
								levels: [
									{
										label: 'None',
										value: 0
									},
									{
										label: 'Min. Access',
										value: 10,
										tooltip: 'Can: Add & Edit. Cannot: Delete',
									},
									{
										label: 'Med. Access',
										value: 20,
										disabled: true
									},
									{
										label: 'Full',
										value: 40,
										tooltip: 'Can Add, Edit and Delete, Cannot modify status as data is inherited from SPC.'
									}

								],
							},
							{
								name: 'Condo Unit Configurator (CUC)',
								id: 'condounits',
								type: 'highrise',
								levels: [
									{
										label: 'None',
										value: 0
									},
									{
										label: 'Min. Access',
										value: 10,
										tooltip: 'Can: Add, edit non published. Cannot: Delete, Modify Status',
									},
									{
										label: 'Med. Access',
										value: 20,
										tooltip: 'Can: Add, edit including published. Cannot: Delete, Modify Status',
									},
									{
										label: 'Full',
										value: 40,
									}
								],
							},
							{
								name: 'Condo Grid Configurator (CGC)',
								id: 'condogrid',
								type: 'highrise',
								levels: [
									{
										label: 'None',
										value: 0
									},
									{
										label: 'Min. Access',
										value: 10,
										disabled: true
									},
									{
										label: 'Med. Access',
										value: 20,
										disabled: true
									},
									{
										label: 'Full',
										value: 40,
									}
								],
							},
						]
					},
					{
						name: 'Marketing & Communications Management',
						apps: [
							{
								name: 'Website & Landing Page Builder (WLPB)',
								id: 'sites',
								levels: [
									{
										label: 'None',
										value: 0
									},
									{
										label: 'Min. Access',
										value: 20,
										tooltip: 'Can: Add, Edit Sites/Blogs. Cannot: Delete, Publish sites',

									},
									{
										label: 'Med. Access',
										value: 30,
										disabled: true
									},
									{
										label: 'Full',
										value: 40,
									},
									{
										label: 'Blog Manager',
										value: 10,
										tooltip: 'Can: Add, edit, publish blogs. Cannot: Add, Edit Sites',
									},
								],
							},
							{
								name: 'Marketing Automation (MA)',
								id: 'marketing-automation',
								levels: [
									{
										label: 'None',
										value: 0
									},
									{
										label: 'Min. Access',
										value: 10,
										tooltip: 'Can add, edit, publish sales workflows (Can only leverage email templates within CRM)',
									},
									{
										label: 'Med. Access',
										value: 20,
										tooltip: 'Can add, edit, publish marketing workflows (Can only leverage Broadcast templates within BEB)',
									},
									{
										label: 'Full',
										value: 40,
										tooltip: 'Can add, edit, publish Mass Marketing Workflows that integrate with Sales Workflows (Can leverage both)'
									}
								],
							},
							{
								name: 'Touchscreen Builder (TB)',
								id: 'touchscreen',
								levels: [
									{
										label: 'None',
										value: 0
									},
									{
										label: 'Min. Access',
										value: 10,
										tooltip: 'Can: Add, edit non published. Cannot: Publish touchscreen link',
									},
									{
										label: 'Med. Access',
										value: 20,
										tooltip: 'Can: Add, edit including published. Cannot: Publish new touchscreen link',
									},
									{
										label: 'Full',
										value: 40,
									}
								],
							},
							{
								name: 'Broadcast Email Builder (BEB)',
								id: 'email',
								levels: [
									{
										label: 'None',
										value: 0
									},
									{
										label: 'Min. Access',
										value: 10,
										tooltip: 'Can: Add, Edit & Send Test. Cannot: Delete, Send Broadcast',
									},
									{
										label: 'Med. Access',
										value: 20,
										disabled: true
									},
									{
										label: 'Full',
										value: 40,
									}
								],
							},
							{
								name: 'Publishing Manager (PM)',
								id: 'domain',
								levels: [
									{
										label: 'None',
										value: 0
									},
									{
										label: 'Min. Access',
										value: 10,
										disabled: true
									},
									{
										label: 'Med. Access',
										value: 20,
										disabled: true
									},
									{
										label: 'Full',
										value: 40,
									}
								],
							},
						]
					},
					{
						name: 'Customer & Transaction Management',
						apps: [
							{
								name: 'Customer Relationship Management (CRM)',
								id: 'contacts',
								levels: [
									{
										label: 'None',
										value: 0
									},
									{
										label: 'Min. Access',
										value: 10,
										tooltip: 'Can: Add & Edit Leads and Activities, Delete personal activities, View personal activity reports, View Their Own Email Sent Threads. Cannot: Delete Lead Records, Delete tags/lead source, or Export Lead Lists',
									},
									{
										label: 'Med. Access',
										value: 20,
										tooltip: 'Can: Add & Edit Leads and Activities, Delete personal activities, View personal activity reports, View All Email Sent Threads. Cannot: Delete Lead Records, Delete tags/lead source, or Export Lead Lists',
									},
									{
										label: 'Full',
										value: 40,
									},
									{
										label: 'Events Manager',
										value: 15,
										tooltip: 'Can: Add & Edit Events, Leads and Activities, Delete personal activities, View personal activity reports. Cannot: Delete Lead Records or Export Lead Lists',
									},
								],
							},
							{
								name: 'Transaction Management (TM)',
								id: 'transactions',
								levels: [
									{
										label: 'None',
										value: 0
									},
									{
										label: 'Min. Access',
										value: 10,
										tooltip: 'Can: Add, View and Edit your own Transactions. Cannot: Void the main document within the Transaction, Cannot: Override Pricing',
									},
									{
										label: 'Med. Access',
										value: 30,
										tooltip: 'Can: Add, View and Edit all Transactions. Cannot: Void the main document within the Transaction, Cannot: Override Pricing'
									},
									{
										label: 'Full',
										value: 40,
									}
								],
							},
							{
								name: 'Inventory & Price Management (IPM)',
								id: 'inventory',
								levels: [
									{
										label: 'None',
										value: 0
									},
									{
										label: 'Min. Access',
										value: 10,
										tooltip: 'Can: Status Change(Only released). Cannot: Unsell Sold Lots, Price bump',
									},
									{
										label: 'Med. Access',
										value: 20,
										tooltip: 'Can: Status Change. Cannot: Unsell Sold Lots, Price bump',
									},
									{
										label: 'Full',
										value: 40,
									}
								],
							},
							{
								name: 'Worksheet Management (WM)',
								id: 'worksheets',
								levels: [
									{
										label: 'None',
										value: 0
									},
									{
										label: 'Min. Access',
										value: 10,
										tooltip: "Can: (Submit, Edit, Approve, Decline, Reserve, Void, Archive, Unarchive, Delete, Make a Transaction) Own Worksheet. Cannot: (Edit, Approve, Decline, Reserve, Void, Archive, Unarchive, Delete) Other's Worksheet, Mark as Sold",
									},
									{
										label: 'Med. Access',
										value: 20,
										tooltip: 'Can: Edit, Approve, Decline, Reserve, Void, Archive, Unarchive, Delete, Make a Transaction, Mark as Sold. Cannot: Submit a Worksheet',
									},
									{
										label: 'Full',
										value: 40,
									}
								],
							},
						],
					}
				]
			},
		},
		methods: {
			submitRole() {
				if (this.roleModal.type == 'add') {
					if (!this.role.name.trim()) return this.$message.error('Please Enter a name for your Role!')
					this.$api.post('/permissions/' + this.instance.id, {
						name: this.role.name,
						permissions: this.role.permissions
					}).then(({ data }) => {
						this.$store.commit('ADD_ROLE', data)
						this.$message.success(`Successfully Created ${this.role.name}`);
						this.close()
					}).catch(() => this.$message.error('An error occurred!'))
				} else if (this.role.id) {
					if (!this.role.name.trim()) return this.$message.error('Please Enter a name for your Role!')
					this.$api.put(`/permissions/${this.instance.id}/${this.role.id}`, {

						name: this.role.name,
						permissions: this.role.permissions

					}).then(({ data }) => {

						this.$store.commit('UPDATE_ROLE', data)
						this.$message.success(`Successfully Updated ${this.role.name == 'bh_agent_manager' ? 'VIP Broker Manager' : this.role.name} role`);
						this.close()
					}).catch(() => this.$message.error('An error occurred!'))
				}
			},
			close() {
				this.$store.commit('CLOSE_ROLE_MODAL')
			}
		}
	}
</script>

<style lang="scss">
	.permissionButtons {
		display: flex;
		gap: 1ch;
	}

	.permission-grid {
		display: grid;
		grid-template-columns: 1fr;
		gap: 1ch;

		@media screen and (min-width:768px) {
			grid-template-columns: 2fr 3fr;
		}
	}

	.permissionButtons .ant-radio-button-wrapper {
		border-radius: 4px;
	}
</style>
